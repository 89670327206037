<template>
  <div>
    <div>
      <b-overlay
        :show="loading"
      >
        <validation-observer ref="formulario">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <h5 class="text-primary">
                    Cantidad a Ingresar:
                  </h5>
                  <validation-provider
                    #default="{ errors }"
                    name="Cantidad a Ingresar"
                    rules="required"
                  >
                    <b-form-input
                      v-model.number="cantidad"
                      type="number"
                      class="text-center"
                      placeholder="0"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <h5 class="text-primary">
                    Referencia:
                  </h5>
                  <validation-provider
                    #default="{ errors }"
                    name="Referencia"
                    rules="required"
                  >
                    <b-form-input
                      v-model="referencia"
                      maxlength="100"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="7" />
              <b-col cols="5">
                <b-button
                  variant="warning"
                  block
                  @click="validarFormulario"
                >
                  Guardar Valores
                  <feather-icon
                    icon="Save  Icon"
                    class="mr-50"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form></validation-observer></b-overlay>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCol, BRow, BFormInput, BFormGroup, BOverlay, BForm,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { updateCreateInventario } from '@/utils/bodega/bodegaInventario'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    idOrigen: {
      type: Number,
      required: true,
    },
    tablaOrigen: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,
      cantidad: null,
      referencia: null,
      ingreso: null,
      showModalIngreso: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    showIngreso() {
      this.showModalIngreso = true
    },
    validarFormulario() {
      this.$refs.formulario.validate()
        .then(success => {
          if (success) {
            this.guardarValores()
          }
        })
    },
    async guardarValores() {
      try {
        this.loading = true
        const usuario = { ...this.usuario }
        const encargado = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
        }
        const ingresoValores = {
          cantidad: this.cantidad,
          referencia: this.referencia, // factura // recibo // etc maximo 100 caracteres
          tipo: 'INGRESO', // INGRESO o DESPACHO
          idOrigen: this.item.id,
          tablaOrigen: this.tablaOrigen,
          fechaCreacion: new Date(),
          user: usuario.email, // correo del usuario logeado
          jsonUsuario: encargado, // json del usuario logeado
        }
        await updateCreateInventario(ingresoValores, 2)
        mensajeInformativo('Valores Ingresados', 'Valores almacenados correctamente!')
      } catch (error) {
        console.log(error)
        mensajeError('Ha ocurrido un inconveniente')
      } finally {
        this.loading = false
        this.$emit('actualizar')
      }
    },
  },
}

</script>
