<template>
  <div>
    <b-button
      variant="warning"
      block
      @click="abrirModalDespacho"
    >
      Generar Despacho de Inventario
      <feather-icon
        icon="EditIcon"
        class="ml-50"
      />
    </b-button>
    <b-modal
      v-model="showModalDespacho"
      :title="'Despacho de inventario de ' + origenReporte.nombre"
      size="lg"
      hide-footer
      ok-title="Aceptar"
      no-close-on-backdrop
      no-close-on-esc
      @hide="limpiarCantidad"
    >
      <b-overlay
        :show="loading"
      >
        <validation-observer ref="formulario">
          <b-form>
            <b-card style="margin-bottom: 8px">
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <h5 class="text-primary">
                      Usuario
                    </h5>
                    <validation-provider
                      #default="{ errors }"
                      name="Usuario"
                      rules="required"
                    >
                      <v-select
                        v-model="usuarioDespacho"
                        :options="usuarios"
                        label="nombre"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-for="(item, index) in itemsIniciales"
                :key="item.id"
              >
                <b-col
                  cols="12"
                >
                  <hr style="margin-bottom: 8px">
                  <b-row
                    :id="item.id"
                    :key="item.id"
                  >
                    <b-col
                      style="margin-top: 5px"
                      cols="6"
                    >
                      <br>
                      <center>
                        <h5 class="text-uppercase text-primary">
                          {{ item.nombre }}
                        </h5>
                      </center>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group>
                        <center>
                          <h5 class="text-uppercase text-primary">
                            Cantidad a despachar
                          </h5>
                          <b-form-input
                            v-model.number="item.cantidad"
                            type="number"
                            class="text-center"
                            placeholder="0"
                          />
                        </center>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <hr>
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="validarFormulario"
                >
                  Guardar despacho
                  <feather-icon
                    icon="SaveIcon"
                    class="mls-50"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { updateCreateInventario } from '@/utils/bodega/bodegaInventario'
import {
  mensajeError, mensajeInformativo, notificacionError, notificacionInformativa,
} from '@/utils/mensajes'
import vSelect from 'vue-select'
import { getUsersByCompany } from '@/utils/usuarios'
import { getlistado } from '@/utils/catalogos'
import { generarReporteDespachos } from '@/utils/bodega/reporteDespachosGeneral'
import { formatDate } from '@/utils/fechas'
import { areaChartOptions } from '@core/components/statistics-cards/chartOptions'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    vSelect,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    itemsIniciales: {
      type: Array,
      required: true,
    },
    idCatalogo: {
      type: Number,
      required: true,
    },
    nombreTabla: {
      type: String,
      required: true,
    },
    nombreCatalogo: {
      type: String,
      required: true,
    },
    origenReporte: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      cantidad: 0,
      loading: false,
      usuarioDespacho: null,
      showModalDespacho: false,
      usuarios: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async created() {
    try {
      this.items = this.itemsIniciales
      this.usuarios = await getUsersByCompany(this.usuario.idCompany)
      this.usuarios.map(usuario => {
        // eslint-disable-next-line no-param-reassign
        usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
        return usuario
      })
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    abrirModalDespacho() {
      this.showModalDespacho = true
    },
    validarFormulario() {
      this.$refs.formulario.validate()
        .then(success => {
          if (success) {
            this.guardarDespacho()
          }
        })
    },
    async validarExistenciaDisponibles(items) {
      await this.loadData()
      // eslint-disable-next-line no-restricted-syntax
      for (const item of items) {
        const disponibles = item.inventario.ingresos - item.inventario.despachos
        if (item.cantidad > disponibles) {
          mensajeError(`La cantidad a despachar para ${item.nombre} excede el valor disponible (${disponibles}).`)
          return false
        }
      }
      return true
    },
    async guardarDespacho() {
      try {
        this.loading = true
        const usuario = { ...this.usuario }
        const encargado = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
        }
        const cantidadesIngresadas = this.itemsIniciales.map(item => item.cantidad)
        if (!await this.validarExistenciaDisponibles(this.itemsIniciales)) {
          return
        }
        this.items.forEach((item, index) => {
          // eslint-disable-next-line no-param-reassign
          item.cantidad = cantidadesIngresadas[index]
        })
        // eslint-disable-next-line no-restricted-syntax
        const despachos = this.items.filter(item => item.cantidad > 0).map(item => ({
          cantidad: item.cantidad,
          tipo: 'DESPACHO',
          idOrigen: item.id,
          tablaOrigen: this.nombreTabla,
          fechaCreacion: new Date(),
          user: usuario.email,
          jsonUsuario: encargado,
          usuarioDespacho: this.usuarioDespacho.email,
          jsonUsuarioDespacho: {
            id: this.usuarioDespacho.id,
            nombre: `${this.usuarioDespacho.firstName} ${this.usuarioDespacho.secondName}`,
            email: this.usuarioDespacho.email,
          },
        }))
        await Promise.all(despachos.map(despacho => updateCreateInventario(despacho, 2)))
        await this.generarDocumentoDespacho(despachos, this.itemsIniciales)
        mensajeInformativo('Despacho Realizado', 'Despacho realizado correctamente!')
      } catch (error) {
        console.log(error)
        mensajeError('Ha ocurrido un inconveniente')
      } finally {
        this.loading = false
        this.$emit('actualizar')
        this.showModalDespacho = false
      }
    },
    async generarDocumentoDespacho(datosDespacho, items) {
      this.loadingDespacho = true
      try {
        const itemsConCantidad = items.filter(item => item.cantidad > 0)
        const fileResumen = await generarReporteDespachos(itemsConCantidad, this.origenReporte)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = `Despacho de ${this.origenReporte.nombre} ${formatDate(new Date(), '/')}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        notificacionInformativa('Documento Descargado', `Reporte de despacho de ${this.origenReporte.nombre}`)
      } catch (error) {
        console.error(error)
        notificacionError('Documento no descargado', 'Inconvenientes al intentar descargar')
      } finally {
        this.loadingDespacho = false
      }
    },
    limpiarCantidad() {
      this.items.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.cantidad = null
      })
      this.usuarioDespacho = null
    },
    async loadData() {
      try {
        this.items = await getlistado(this.idCatalogo, this.nombreCatalogo, true, this.usuario)
        if (this.items === null) this.items = []
      } catch (error) {
        console.error(`Error en cargar ${this.nombreCatalogo}`, error)
        this.items = []
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
