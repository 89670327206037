<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col cols="3">
            <h5 class="text-primary">
              Nombre:
            </h5>
          </b-col>
          <b-col cols="8">
            <b-form-group>
              <b-form-input
                v-model="item.nombre"
                class="text-center"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <h5 class="text-primary">
              Valor Costo:
            </h5>
          </b-col>
          <b-col cols="8">
            <b-form-group>
              <b-form-input
                v-model="item.precio.costo"
                class="text-center"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <h5 class="text-primary">
              Valor Venta:
            </h5>
          </b-col>
          <b-col cols="8">
            <b-form-group>
              <b-form-input
                v-model="item.precio.precioVenta"
                class="text-center"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" />
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-tabs
          content-class="pt-1"
          fill
        >
          <b-tab title="INGRESOS">
            <vue-good-table
              :columns="columnsIngresos"
              :rows="rowsIngresos"
              style-class="vgt-table condensed"
            >
              <div slot="emptystate">
                <center>
                  Aún no hay ingresos
                </center>
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'fecha'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ calcularFecha(props.row.fechaCreacion) }}</span>
                </span>
              </template>
            </vue-good-table>
          </b-tab>
          <b-tab title="DESPACHOS">
            <!--b-row>
              <b-col
                class="d-flex justify-content-end mb-1 mt-n1"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="generarReporte(rowsDespachos)"
                >
                  Descargar PDF
                </b-button>
              </b-col>
            </b-row-->

            <vue-good-table
              :columns="columnsDespachos"
              :rows="rowsDespachos"
              style-class="vgt-table condensed"
            >
              <div slot="emptystate">
                <center>
                  Aún no hay despachos
                </center>
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'fecha'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ calcularFecha(props.row.fechaCreacion) }}</span>
                </span>
                <span
                  v-else-if="props.column.field === 'opciones'"
                  class="text-nowrap"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    dropleft
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      @click="generarDocumento(props.row)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                      />
                      <span>Imprimir Reporte</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </template>
            </vue-good-table>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BTabs, BTab, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { findInventario, generarReporte, generarReporteDespachadas } from '@/utils/bodega/bodegaInventario'
import { calcularFecha } from '@/utils/fechas'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BDropdown,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BDropdownItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    nombreTabla: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rowsIngresos: [],
      rowsDespachos: [],
      camposTabla: [
        { key: 'fecha', label: 'Fecha' },
        { key: 'usuario', label: 'Usuario' },
        { key: 'cantidad', label: 'Cantidad' },
        { key: 'factura', label: 'Factura' },
      ],
      columnsIngresos: [
        {
          label: 'Fecha',
          field: 'fecha',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Usuario',
          field: 'jsonUsuario.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Cantidad',
          field: 'cantidad',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Factura',
          field: 'referencia',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnsDespachos: [
        {
          label: 'Fecha',
          field: 'fecha',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Usuario',
          field: 'jsonUsuario.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Cantidad',
          field: 'cantidad',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Opciones',
          field: 'opciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
    }
  },
  async created() {
    const filterIngresos = {
      where: {
        tablaOrigen: this.nombreTabla,
        idOrigen: this.item.id,
      },
    }
    const inventario = await findInventario(filterIngresos)
    this.rowsIngresos = inventario.filter(item => item.tipo === 'INGRESO')
    this.rowsDespachos = inventario.filter(item => item.tipo === 'DESPACHO')
  },
  methods: {
    calcularFecha,
    async generarReporte(rowsDespachos) {
      try {
        const fileResumen = await generarReporte(rowsDespachos)
        if (fileResumen) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = 'Reporte Despachos.pdf'
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async generarDocumento(params) {
      try {
        const fileResumen = await generarReporteDespachadas(params, this.item)
        if (fileResumen) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = 'Reporte Despachos.pdf'
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        notificacionInformativa('Documento Descargado', `Reporte de despacho de ${this.item.nombre}`)
      } catch (error) {
        notificacionError('Documento no descargado', 'Inconvenientes al intentar descargar el documento')
        console.log(error)
      }
    },
  },
}

</script>
