import RequestAdmin from '@/utils/request'

async function calcularUltimoSeguimiento(datosDespacho) {
  // Variable para almacenar el último despacho encontrado
  let ultimoDespacho = null

  // Recorre cada entrada de datosDespacho para encontrar el último despacho
  for (let i = datosDespacho.length - 1; i >= 0; i--) {
    const { seguimiento } = datosDespacho[i]

    // Recorre el seguimiento desde el final hacia el principio
    for (let j = seguimiento.length - 1; j >= 0; j--) {
      // Encuentra el primer objeto con estado 'DESPACHO'
      if (seguimiento[j].estado === 'DESPACHO') {
        ultimoDespacho = seguimiento[j]
        break // Detiene la búsqueda una vez encontrado
      }
    }

    if (ultimoDespacho) {
      break // Si se encuentra el último despacho, detiene la búsqueda principal
    }
  }

  // Verifica si se encontró algún despacho
  if (!ultimoDespacho) {
    throw new Error('No se encontró ningún objeto con el estado "DESPACHO".')
  }

  // Retorna el último despacho encontrado
  return ultimoDespacho
}
// eslint-disable-next-line import/prefer-default-export
export async function generarReporteDespachos(datosDespacho, origen, usuario, grupoAsignado, mejoraSeleccionada, personaRecibe, dpi) {
  const fechaActual = new Date().toLocaleDateString('es-ES')
  const usuarioEncargado = `${usuario.firstName} ${usuario.firstSurname}`
  const logoEmpresa = usuario.company.name === 'APMIX' ? 'https://apsistema.com/img/apmixlogo.68a26a4f.png' : 'https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg'
  const ultimoDespacho = await calcularUltimoSeguimiento(datosDespacho)
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE DESPACHO</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .fecha {
      font-family: sans-serif;
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 16pt;
    }
    .headerLogos{
      width: 100%;
      margin-bottom: 5px;
      margin-top: 25px;
      margin-bottom: 20px;
      border-collapse: collapse;
    }
    .headerTitulos{
      text-transform: uppercase;
      background-color: #1f88c2;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      color: white;
      font-family: sans-serif;
      text-align: justify;
    }
    .titulosDatosColores {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      padding: 3px;
      width: 300px;
    }
    td {
      border: 1px solid black;
      padding: 8px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      font-family: sans-serif;
      font-size: 1.4em;
    }
    th {
      border: 1px solid black;
      color: yellow;
      margin-right: 25px;
      margin-left: 25px;
      font-family: sans-serif;
      text-align: center;
      font-weight: bold;
      font-size: 1.5em;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    p {
      font-family: sans-serif;
      font-weight: bold;
      font-size: 16pt;
      text-align: justify;
    }
    .Imagenes{
      width: 100%;
      border-collapse: collapse;
    }
    .table {
      border: 1px solid black;
      border-collapse: collapse;
      width: 100%;
      padding-right: 50px;
    }
    .lineaFirma {
      border-top: 1px solid black;
      margin-left: auto;
      margin-right: auto;
      width: 40%;
    }
    .lineaFirma3 {
      border-top: 1px solid black;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    .lineaFirma{
      border-top: 1px solid black;
      width: 25%;
      display: flex;
      margin-top: 150px;
      font-family: arial,serif;
    }
    .linea {
      position: relative;
      margin-top: 40px;
      width: 100%;
    }
    .lineas {
      position: relative;
      width: 100%;
    }
    .linea hr {
      border: none;
      border-top: 1px solid black;
      margin-bottom: 15px;
    }
    .texto {
      text-transform: uppercase;
      text-align: center;
      font-family: arial,serif;
    }
    .centro {
      display: flex;
    }
    .linea span {
      width: 80%;
      position: absolute;
      top: -15px;
      transform: translate(-50%, -50%);
    }
    .lineas hr {
      border: none;
      border-top: 1px solid black;
      margin-bottom: 15px;
    }
    .lineas span {
      width: 80%;
      position: absolute;
      top: -15px;
      transform: translate(-50%, -50%);
    }
    .tituloDatosImagenes {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      font-weight: bold;
      width: 38.5%;
      text-align: center;
      font-size: 1em;
      padding: 10px;
      margin: 0 auto;
      border: 1px solid black;
    }
</style>
<body>
<div class="fecha">${fechaActual}</div>
<table class="headerLogos">
  <tr>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="${logoEmpresa}" alt="logo empresa"/>
    </th>
    <th width="66.64%" height="20px" class="headerTitulos"> <center>HOJA DE ENTREGA DE ${origen.nombre}<br>${usuario.company.companyStyle.headerReportes}</center></th>
  </tr>
</table>
<!--Pantallas Principales -->
${origen.origen === 'LUMINARIAS' && usuario.company.name !== 'APMIX' ? `
<table>
  <thead>
    <tr>
      <th style="width: 10%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
      <th style="width: 20%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">SERIE</th>
      <th style="width: 25%;color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO LÁMPARA</th>
      <th style="width: 20%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">GRUPO DESPACHADO</th>
      <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DESTINO DE DESPACHO</th>
    </tr>
  </thead>
  <tbody>
    ${datosDespacho.map((datos, index) => `
    <tr>
      <td>
        <center>
        ${index + 1}
        <center>
      </td>
      <td>
        <center>
        ${datos.serie}
        <center>
      </td>
      <td>
        <center>
          ${datos.tipoLampara.nombre}
        </center>
      </td>
      <td>
      <center>
        ${ultimoDespacho.grupo.nombre}
      <center>
      </td>
      <td>
       <center>
         ${ultimoDespacho.mejorasRealizadas.nombre.toUpperCase()}
        <center>
    </td>
      </tr>
      `).join('')}
    </tbody>
</table>
` : ''}
${origen.origen === 'LUMINARIAS' && usuario.company.name === 'APMIX' ? `
<table>
  <thead>
    <tr>
      <th style="width: 10%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">SERIE</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO LÁMPARA</th>
    </tr>
  </thead>
  <tbody>
    ${datosDespacho.map((datos, index) => `
    <tr>
      <td>
        <center>
        ${index + 1}
        <center>
      </td>
      <td>
        <center>
        ${datos.serie}
        <center>
      </td>
      <td>
        <center>
          ${datos.tipoLampara.nombre}
        </center>
      </td>
      </tr>
      `).join('')}
    </tbody>
</table>
` : ''}
${origen.origen === 'NEMAS' ? `
<table>
  <thead>
    <tr>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">SERIE</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">POTENCIA</th>
    </tr>
  </thead>
  <tbody>
    ${datosDespacho.map((datos, index) => `
    <tr>
      <td style="width: 8%">
        <center>
        ${index + 1}
        <center>
      </td>
      <td>
        <center>
        ${datos.serie}
        </center>
      </td>
      <td>
        <center>
          ${datos.potencia.nombre}
        </center>
      </td>
      </tr>
      `).join('')}
    </tbody>
</table>
` : ''}
${origen.origen === 'POSTES' ? `
  <table>
    <thead>
      <tr>
        <th style="width: 40%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NOMBRE</th>
        <th style="width: 20%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">INGRESADOS</th>
        <th style="width: 15%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DESPACHADOS</th>
        <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DISPONIBLES</th>
      </tr>
    </thead>
    <tbody>
      ${datosDespacho.map(datos => {
    const disponibles = datos.inventario.ingresos - datos.inventario.despachos
    return `
          <tr>
            <td style="width: 25%">
              <center>
                ${datos.nombre}
              <center>
            </td>
            <td style="width: 25%">
              <center>
                ${datos.inventario.ingresos}
              </center>
            </td>
            <td style="width: 25%">
              <center>
                ${datos.inventario.despachos}
              </center>
            </td>
            <td style="width: 25%">
              <center>
                ${disponibles}
              </center>
            </td>
          </tr>
`
  }).join('')}
        </tbody>
      </table>
    ` : ''}
${origen.origen === 'BRAZOS' ? `
  <table>
    <thead>
      <tr>
        <th style="width: 40%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NOMBRE</th>
        <th style="width: 20%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">INGRESADOS</th>
        <th style="width: 15%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DESPACHADOS</th>
        <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DISPONIBLES</th>
      </tr>
    </thead>
    <tbody>
      ${datosDespacho.map(datos => {
    const disponibles = datos.inventario.ingresos - datos.inventario.despachos
    return `
          <tr>
            <td style="width: 25%">
              <center>
                ${datos.nombre}
              <center>
            </td>
            <td style="width: 25%">
              <center>
                ${datos.inventario.ingresos}
              </center>
            </td>
            <td style="width: 25%">
              <center>
                ${datos.inventario.despachos}
              </center>
            </td>
            <td style="width: 25%">
              <center>
                ${disponibles}
              </center>
            </td>
          </tr>
`
  }).join('')}
        </tbody>
      </table>
    ` : ''}
${origen.origen === 'HERRAJES' ? `
  <table>
    <thead>
      <tr>
        <th style="width: 40%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NOMBRE</th>
        <th style="width: 20%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">INGRESADOS</th>
        <th style="width: 15%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DESPACHADOS</th>
        <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DISPONIBLES</th>
      </tr>
    </thead>
    <tbody>
      ${datosDespacho.map(datos => {
    const disponibles = datos.inventario.ingresos - datos.inventario.despachos
    return `
          <tr>
            <td style="width: 25%">
              <center>
                ${datos.nombre}
              <center>
            </td>
            <td style="width: 25%">
              <center>
                ${datos.inventario.ingresos}
              </center>
            </td>
            <td style="width: 25%">
              <center>
                ${datos.inventario.despachos}
              </center>
            </td>
            <td style="width: 25%">
              <center>
                ${disponibles}
              </center>
            </td>
          </tr>
`
  }).join('')}
        </tbody>
      </table>
    ` : ''}
<!--Al realizar un Despacho-->
${origen.origen === 'DESPACHO' ? `
  <table>
    <thead>
      <tr>
        <th style="width: 10%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
        <th style="width: 20%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CANTIDAD</th>
        <th style="width: 80%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NOMBRE</th>
      </tr>
    </thead>
    <tbody>
      ${datosDespacho.map((datos, index) => `
          <tr>
            <td style="width: 10%">
              <center>
                ${index + 1}
              <center>
            </td>
            <td style="width: 20%">
              <center>
                ${datos.cantidad}
              <center>
            </td>
            <td style="width: 80%">
              <center>
                ${datos.nombre}
              </center>
            </td>
          </tr>
`).join('')}
        </tbody>
      </table>
    ` : ''}
<br>
<br>
<p>Yo<span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 25%;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${personaRecibe}</span>, con numéro de DPI<span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 20%;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${dpi}</span>, recibo conforme de bodega ${usuario.company.name}, ${origen.concordancia} ${origen.nombre} ${origen.concordanciaDos} en este documento.</p>
<br>
<br>
<table>
  <tr>
    <td style="width: 2%; border: none" />
    <td style="width: 30%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
    <td style="width: 2%; border: none" />
    <td style="width: 30%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
    <td style="width: 2%; border: none" />
    <td style="width: 30%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
    <td style="width: 2%; border: none" />
  </tr>
  <tr>
    <td style="width: 2%; border: none" />
    <td style="width: 30%; border: none"><center><span>BODEGA<br>${usuarioEncargado}</span></center></td>
    <td style="width: 2%; border: none" />
    <td style="width: 30%; border: none"><center><span>FIRMA RECIBIDO</span></center></td>
    <td style="width: 2%; border: none" />
    <td style="width: 30%; border: none"><center><span>SELLO</span></center></td>
    <td style="width: 2%; border: none" />
  </tr>
</table>
<br>
<br>
<br>
<table class="Imagenes">
  <tr>
    <th class="titulosDatosColores">OBSERVACIONES</th>
  </tr> 
  <tr>
    <td>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 15%"></div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'horizontal',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
