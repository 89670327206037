<template>
  <div>
    <b-overlay
      :show="loading"
    >
      <b-row>
        <b-col cols="7" />
        <b-col cols="4">
          <despacho
            :items-iniciales="datosTabla"
            :id-catalogo="idCatalogo"
            :nombre-tabla="nombreTabla"
            :nombre-catalogo="nombreCatalogo"
            :origen-reporte="origenReporte"
            @actualizar="actualizar"
          />
        </b-col>
        <b-col
          v-if="usuario.company.enabledReport"
          cols="1"
        >
          <reporte-general
            :rows="rowsReporte"
            :columns="columnsInventarios"
            :titulo-descarga="tituloDescarga"
            :subtitulo="subtitulo"
            :titulo-secundario="tituloSecundario"
            :titulo-principal="tituloPrincipal"
          />
        </b-col>
        <!--b-col cols="1">
          <table>
            <tr>
              <td>
                <b-overlay :show="loadingDespacho">
                  <b-button
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    v-b-tooltip.hover.v-primary
                    title="Descargar Reporte Lista PDF"
                    variant="primary"
                    class="btn-icon"
                    @click="generarDocumentoDespacho()"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                </b-overlay>
              </td>
            </tr>
          </table>
        </b-col-->
      </b-row>
      <br>
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columnas"
            :rows="datosTabla"
            style-class="vgt-table"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar...'
            }"
            @on-row-dblclick="showDetalle"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'detalle'">
                <b-dropdown
                  v-if="props.row.estado !=='ANULADO' && props.row.estado !== 'FINALIZADA'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="showIngreso(props)">
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span>Ingresar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <span v-else-if="props.column.field === 'existencia'">
                {{ validarExistencia(props) }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-overlay>
    <div>
      <b-modal
        v-model="showModalDetalle"
        title="Detalle del Inventario de los Postes"
        size="lg"
        ok-only
        ok-title="Aceptar"
        no-close-on-backdrop
      >
        <detalle-inventario
          :item="item"
          :nombre-tabla="nombreTabla"
        />
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="showModalIngreso"
        :title="'Ingreso de Inventario de ' + origenReporte.nombre"
        size="md"
        ok-only
        modal-class="modal-primary"
        ok-title="Aceptar"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
      >
        <ingreso-valores
          :item="item"
          :id-origen="idCatalogo"
          :tabla-origen="nombreTabla"
          @actualizar="actualizar"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BPagination, BFormSelect, BDropdown, BDropdownItem, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import IngresoValores from '@/components/bodega/ingresoValores.vue'
import Despacho from '@/components/bodega/despacho.vue'
import DetalleInventario from '@/components/bodega/detalleInventario.vue'
import { formatDate } from '@/utils/fechas'
import { generarReporteDespachos } from '@/utils/bodega/reporteDespachosGeneral'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'
import Ripple from 'vue-ripple-directive'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import {
  tituloDescarga, subtitulo, tituloPrincipal, tituloSecundario, getColumns, getRows,
} from '@/utils/bodega/inventariosExcel'

export default {
  components: {
    ReporteGeneral,
    DetalleInventario,
    Despacho,
    IngresoValores,
    BRow,
    BCol,
    BOverlay,
    BDropdown,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    columnas: {
      type: Array,
      required: true,
    },
    datosTabla: {
      type: Array,
      required: true,
    },
    idCatalogo: {
      type: Number,
      required: true,
    },
    nombreTabla: {
      type: String,
      required: true,
    },
    nombreCatalogo: {
      type: String,
      required: true,
    },
    origenReporte: {
      type: Object,
      required: true,
    },
    origenReporteTabla: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rowsReporte: [],
      columnsInventarios: [],
      loadingDespacho: false,
      item: null,
      showModalDetalle: false,
      showModalIngreso: false,
      loading: false,
      pageLength: 10,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
  },
  watch: {
    datosTabla: {
      immediate: true, // Ejecutar inmediatamente después de la creación del componente
      handler(newValue) {
        this.columnsInventarios = getColumns()
        this.rowsReporte = getRows([...newValue])
      },
    },
  },
  methods: {
    validarExistencia(params) {
      const item = params.row
      const disponibles = item.inventario.ingresos - item.inventario.despachos
      return disponibles
    },
    async generarDocumentoDespacho() {
      this.loadingDespacho = true
      try {
        const fileResumen = await generarReporteDespachos(this.datosTabla, this.origenReporteTabla)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = `Despacho de ${this.origenReporteTabla.nombre} ${formatDate(new Date(), '/')}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        notificacionInformativa('Documento Descargado', `Reporte de despacho de ${this.origenReporteTabla.nombre}`)
      } catch (error) {
        console.error(error)
        notificacionError('Documento no descargado', 'Inconvenientes al intentar descargar el documento')
      } finally {
        this.loadingDespacho = false
      }
    },
    showDetalle(params) {
      this.item = params.row
      this.showModalDetalle = true
    },
    showIngreso(params) {
      this.item = params.row
      this.showModalIngreso = true
    },
    actualizar() {
      this.showModalIngreso = false
      this.$emit('actualizar')
    },
  },
}
</script>
