import config from '@/utils/config'
import RequestAdmin from '@/utils/request'
import { formatDate } from '@/utils/fechas'

const url = `${config.URL_BASE}inventario-unidades`

export async function updateCreateInventario(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar inventario', err)
    return null
  }
}
export async function findInventario(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener inventario', err)
    return null
  }
}
export async function getInventario(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar inventario', err)
    return null
  }
}

export async function generarReporte(datosDespacho) {
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE DESPACHO</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headerLogos{
        width: 100%;
        margin-bottom: 5px;
        margin-top: 25px;
        margin-bottom: 20px;
        border-collapse: collapse;
    }
    .headerTitulos{
        background-color: #1f88c2;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
        font-family: sans-serif;
        text-align: justify;
    }
    .titulosDatosColores {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 300px;
    }
    td {
      border: 1px solid black;
      padding: 8px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      font-family: sans-serif;
      font-size: 1.4em;
    }
    th {
      border: 1px solid black;
      color: yellow;
      margin-right: 25px;
      margin-left: 25px;
      font-family: sans-serif;
      text-align: center;
      font-weight: bold;
      font-size: 1.5em;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    p {
      font-family: sans-serif;
      font-weight: bold;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }
    .lineas {
            position: relative;
            width: 100%;
    }
    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .lineas hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .lineas span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
</style>
<body>
<table class="headerLogos">
  <tr>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://apsistema.com/img/apmixlogo.68a26a4f.png" alt="logo apmix"/>
    </th>
    <th width="66.64%" height="20px" class="headerTitulos"> <center>Reporte de Despacho<br>Dirección de Servicios Públicos<br>Municipalidad de Mixco</center></th>
  </tr>
</table>
<br>
<table>
  <thead>
    <tr>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">FECHA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">USUARIO QUE DESPACHA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">USUARIO AL QUE SE DESPACHA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CANTIDAD DESPACHADA</th>
    </tr>
  </thead>
  <tbody>
    ${datosDespacho.map(despacho => `
        <tr>
          <td style="width: 10%">
            <center>
            ${formatDate(despacho.fechaCreacion, '/')}
              
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${despacho.jsonUsuario.nombre}
            </center>
          </td>
          <td style="width: 20%">
            <center>
              ${despacho.jsonUsuarioDespacho.nombre}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${despacho.cantidad}
            </center>
          </td>
        </tr>
      `).join('')}
  </tbody>
</table>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'horizontal',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
export async function generarReporteDespachadas(datosDespachadas, origen) {
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE DESPACHO</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headerLogos{
        width: 100%;
        margin-bottom: 5px;
        margin-top: 25px;
        margin-bottom: 20px;
        border-collapse: collapse;
    }
    .headerTitulos{
        text-transform: uppercase;
        background-color: #1f88c2;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
        font-family: sans-serif;
        text-align: justify;
    }
    .titulosDatosColores {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 300px;
    }
    td {
      border: 1px solid black;
      padding: 8px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      font-family: sans-serif;
      font-size: 1.4em;
    }
    th {
      border: 1px solid black;
      color: yellow;
      margin-right: 25px;
      margin-left: 25px;
      font-family: sans-serif;
      text-align: center;
      font-weight: bold;
      font-size: 1.5em;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    p {
      font-family: sans-serif;
      font-weight: bold;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }
    .lineas {
            position: relative;
            width: 100%;
    }
    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .lineas hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .lineas span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
</style>
<body>
<table class="headerLogos">
  <tr>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://apsistema.com/img/apmixlogo.68a26a4f.png" alt="logo apmix"/>
    </th>
    <th width="66.64%" height="20px" class="headerTitulos"> <center>Reporte de Despachos<br>Municipalidad de Mixco</center></th>
  </tr>
</table>
<br>
<p>Mediante el siguiente documento, Yo <span style="border-bottom: 1px solid #000; display: inline-block; width: 28%;"></span>, certifico que ${origen.nombre} descritas en la siguiente tabla se están entregando en buen estado.</p>
<table>
  <thead>
    <tr>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CANTIDAD</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NOMBRE</th>
    </tr>
  </thead>
  <tbody>
        <tr>
          <td style="width: 15%">
            <center>
              ${datosDespachadas.cantidad}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${origen.nombre}
            </center>
          </td>
        </tr>
  </tbody>
</table>
<br>
<br>
<p>Yo  <span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 20%;"></span>, acuso de recibir ${origen.nombre} descritas en la tabla anterior, confirmando que el estado y las especificaciones son conforme a lo acordado y se reciben en buen estado.</p>
<br>
<br>
<br>
<p>Firma  <span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 28%;"></span></p>
<br>
<br>
<br>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'horizontal',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
