import {
  estiloColumns,
  estiloRows,
  estiloSubtitulos,
  estiloTitulos,
} from '@/utils/reportes/estilosReportes/estilosReportesGenerales'

export const tituloPrincipal = {
  titulo: 'ALUMBRADO PÚBLICO DE MIXCO, S.A',
  style: estiloTitulos,
}

export const tituloSecundario = {
  titulo: 'INVENTARIO',
  style: estiloTitulos,
}

export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE INVENTARIO',
  style: estiloSubtitulos,
}

export const tituloDescarga = 'REPORTE INVENTARIOS'

export function validarExistencia(ingresos, despachos) {
  const disponibles = ingresos - despachos
  return disponibles
}

export function getColumns() {
  return [
    {
      label: 'Nombre',
      width: 40,
      style: estiloColumns,
    },
    {
      label: 'Ingresos',
      width: 15,
      style: estiloColumns,
    },
    {
      label: 'Despachos',
      width: 15,
      style: estiloColumns,
    },
    {
      label: 'Existencia',
      width: 15,
      style: estiloColumns,
    },
  ]
}

export function getRows(rows) {
  const listado = []

  rows.forEach(row => {
    const disponibles = validarExistencia(row.inventario.ingresos, row.inventario.despachos)
    const item = {
      nombre: {
        value: row.nombre,
        type: 'string',
        style: estiloRows,
      },
      ingresos: {
        value: row.inventario.ingresos,
        type: 'number',
        style: estiloRows,
      },
      despachos: {
        value: row.inventario.despachos,
        type: 'number',
        style: estiloRows,
      },
      existencia: {
        value: disponibles,
        type: 'number',
        style: estiloRows,
      },
    }
    listado.push(item)
  })
  return listado
}
